exports.components = {
  "component---src-components-exports-aboutus-js": () => import("./../../../src/components/exports/aboutus.js" /* webpackChunkName: "component---src-components-exports-aboutus-js" */),
  "component---src-components-exports-billing-address-js": () => import("./../../../src/components/exports/billingAddress.js" /* webpackChunkName: "component---src-components-exports-billing-address-js" */),
  "component---src-components-exports-contact-js": () => import("./../../../src/components/exports/contact.js" /* webpackChunkName: "component---src-components-exports-contact-js" */),
  "component---src-components-exports-login-as-vendor-js": () => import("./../../../src/components/exports/loginAsVendor.js" /* webpackChunkName: "component---src-components-exports-login-as-vendor-js" */),
  "component---src-components-exports-orders-js": () => import("./../../../src/components/exports/orders.js" /* webpackChunkName: "component---src-components-exports-orders-js" */),
  "component---src-components-exports-payment-js": () => import("./../../../src/components/exports/payment.js" /* webpackChunkName: "component---src-components-exports-payment-js" */),
  "component---src-components-exports-privacypolicy-js": () => import("./../../../src/components/exports/privacypolicy.js" /* webpackChunkName: "component---src-components-exports-privacypolicy-js" */),
  "component---src-components-exports-terms-js": () => import("./../../../src/components/exports/terms.js" /* webpackChunkName: "component---src-components-exports-terms-js" */),
  "component---src-components-exports-vendors-js": () => import("./../../../src/components/exports/vendors.js" /* webpackChunkName: "component---src-components-exports-vendors-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-about-us-jsx": () => import("./../../../src/pages/aboutUs/AboutUs.jsx" /* webpackChunkName: "component---src-pages-about-us-about-us-jsx" */),
  "component---src-pages-billing-address-billing-adress-jsx": () => import("./../../../src/pages/billingAddress/billingAdress.jsx" /* webpackChunkName: "component---src-pages-billing-address-billing-adress-jsx" */),
  "component---src-pages-dashboard-dashboard-jsx": () => import("./../../../src/pages/dashboard/Dashboard.jsx" /* webpackChunkName: "component---src-pages-dashboard-dashboard-jsx" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index-2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-login-as-vendor-login-as-vendor-jsx": () => import("./../../../src/pages/loginAsVendor/LoginAsVendor.jsx" /* webpackChunkName: "component---src-pages-login-as-vendor-login-as-vendor-jsx" */),
  "component---src-pages-orders-orders-jsx": () => import("./../../../src/pages/orders/orders.jsx" /* webpackChunkName: "component---src-pages-orders-orders-jsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-page-page-index-jsx": () => import("./../../../src/pages/page/pageIndex.jsx" /* webpackChunkName: "component---src-pages-page-page-index-jsx" */),
  "component---src-pages-page-page-jsx": () => import("./../../../src/pages/page/Page.jsx" /* webpackChunkName: "component---src-pages-page-page-jsx" */),
  "component---src-pages-payment-payment-jsx": () => import("./../../../src/pages/payment/payment.jsx" /* webpackChunkName: "component---src-pages-payment-payment-jsx" */),
  "component---src-pages-poster-poster-jsx": () => import("./../../../src/pages/poster/Poster.jsx" /* webpackChunkName: "component---src-pages-poster-poster-jsx" */),
  "component---src-pages-privacypolicy-privacypolicy-jsx": () => import("./../../../src/pages/privacypolicy/Privacypolicy.jsx" /* webpackChunkName: "component---src-pages-privacypolicy-privacypolicy-jsx" */),
  "component---src-pages-reorder-reorder-jsx": () => import("./../../../src/pages/reorder/reorder.jsx" /* webpackChunkName: "component---src-pages-reorder-reorder-jsx" */),
  "component---src-pages-search-page-search-page-jsx": () => import("./../../../src/pages/searchPage/SearchPage.jsx" /* webpackChunkName: "component---src-pages-search-page-search-page-jsx" */),
  "component---src-pages-templete-detail-templete-detail-jsx": () => import("./../../../src/pages/templeteDetail/TempleteDetail.jsx" /* webpackChunkName: "component---src-pages-templete-detail-templete-detail-jsx" */),
  "component---src-pages-terms-terms-jsx": () => import("./../../../src/pages/terms/Terms.jsx" /* webpackChunkName: "component---src-pages-terms-terms-jsx" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-vendors-vendors-jsx": () => import("./../../../src/pages/vendors/vendors.jsx" /* webpackChunkName: "component---src-pages-vendors-vendors-jsx" */),
  "component---src-pages-view-order-view-order-jsx": () => import("./../../../src/pages/viewOrder/viewOrder.jsx" /* webpackChunkName: "component---src-pages-view-order-view-order-jsx" */)
}

